import type React from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import useChallenges from "../hooks/useChallenges";
import type { Exam, TestPresentationProps } from "../types";
import ExamTypeSection from "./ExamTypeSection";

const TestPresentation: React.FC<TestPresentationProps> = ({
  tests,
  isAdminMode,
  challenges,
}) => {
  const [showAMC, setShowAMC] = useState(true);
  const navigate = useNavigate();
  const { userProfile, viewAsUserProfile } = useAuth();

  // Get graded challenges and isViewingAsOtherUser from useChallenges
  const { gradedChallenges, isViewingAsOtherUser } = useChallenges();

  const groupedExams = useMemo(() => {
    const grouped: Record<string, Exam[]> = {};
    for (const exam of tests) {
      const groupName = exam.examType;
      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }
      grouped[groupName].push(exam);
    }
    return grouped;
  }, [tests]);

  const amcGroupOrder = ["AMC-8", "AMC-10", "AMC-12"];
  const orderedAMCGroups = amcGroupOrder
    .filter((type) => groupedExams[type])
    .map((type) => ({
      type,
      exams: groupedExams[type],
    }));

  const nonAMCTests = tests.filter(
    (test) => !amcGroupOrder.includes(test.examType),
  );

  return (
    <div className="p-4 bg-gray-50">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-4">
        {/* Header section */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Test Progress Dashboard</h1>
          {isViewingAsOtherUser && (
            <div className="text-sm text-gray-600">
              Viewing progress for {viewAsUserProfile?.name}
            </div>
          )}
          <button
            type="button"
            onClick={() => setShowAMC(!showAMC)}
            className="bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 transition-colors"
          >
            {showAMC ? "Switch to Non-AMC View" : "Switch to AMC View"}
          </button>
        </div>

        {showAMC ? (
          <div className="h-96 overflow-y-auto">
            {orderedAMCGroups.map(({ type, exams }) => (
              <ExamTypeSection
                key={type}
                type={type}
                exams={exams}
                isAdminMode={isAdminMode}
                challenges={challenges}
                gradedChallenges={gradedChallenges}
                viewAsUserProfile={viewAsUserProfile}
                userProfile={userProfile}
                isViewingAsOtherUser={isViewingAsOtherUser}
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            {nonAMCTests
              .sort((a, b) => Number(a.year) - Number(b.year))
              .map((test) => (
                <div
                  key={test.id}
                  className="bg-white rounded-md shadow-sm p-3 flex flex-col justify-between border hover:bg-gray-50 transition-colors"
                >
                  <div>
                    <h3 className="font-semibold text-sm">
                      {test.competition}
                    </h3>
                    <p className="text-gray-500 text-xs">
                      {`${test.year} - ${test.name}`}
                    </p>
                  </div>
                  {isAdminMode ? (
                    <button
                      type="button"
                      onClick={() => navigate(`/exam/${test.id}`)}
                      className="mt-2 bg-blue-500 text-white py-1 px-2 rounded text-xs hover:bg-blue-600 transition-colors"
                      aria-label={`Edit ${test.name}`}
                    >
                      Edit Test
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        !isViewingAsOtherUser && navigate(`/exam/${test.id}`)
                      }
                      className={`mt-2 py-1 px-2 rounded text-xs transition-colors ${
                        isViewingAsOtherUser
                          ? "bg-gray-300 cursor-not-allowed"
                          : "bg-blue-500 hover:bg-blue-600 text-white"
                      }`}
                      disabled={isViewingAsOtherUser}
                    >
                      Take Test
                    </button>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPresentation;
